define('tm3/pods/scheduling/route', ['exports', 'tm3/mixins/scheduling-route', 'moment'], function (exports, _schedulingRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_schedulingRoute.default, {
        model: function model() {
            return Ember.RSVP.hash({
                current_user: this.store.findRecord('user', this.get('session.data.authenticated.id')),
                current_employee: this.store.query('employee', {
                    id: this.get('session.data.authenticated.id'),
                    status: 'Active',
                    include_in_scheduling: '1',
                    sort: 'first_name,last_name'
                }),
                start_date: (0, _moment.default)(new Date()).format('YYYY-MM-DD'),
                all_allocation_types: this.store.findAll('time_allocation_type'),
                all_cases: this.store.query('matter', {
                    status: 'Active',
                    sort: '-tm_num,name,start_date,-created_on'
                }),
                cases_selected: [],
                hide_case_events: true,
                hide_summary: true,
                skills: this.store.query('skill', {
                    sort: 'name'
                }),
                all_teams: this.store.query('team', {
                    mode: 'Employee',
                    sort: 'name'
                }),
                all_employees: this.store.query('employee', {
                    status: 'Active',
                    include_in_scheduling: '1',
                    sort: 'first_name,last_name'
                }),
                employees_selected: [],
                teams_selected: [],
                skills_selected: []
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            // Initial population of schedule
            controller.get('getCaseEventsByDay').perform();
            controller.get('getNeedsWorkAtAGlance').perform();
        }
    });
});