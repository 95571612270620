define('tm3/pods/report/info/project-tracker/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),

        apiName: 'project_tracker_report',

        queryParams: {
            sortField: {
                refreshModel: true
            },
            owner: {
                refreshModel: true
            },
            budget_type: {
                refreshModel: true
            },
            case_type: {
                refreshModel: true
            },
            assignee: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage,
                    page: params.page ? params.page : 1,
                    sort_field: params.sortField,
                    owner_id: params.owner ? params.owner : false,
                    budget_type: params.budget_type ? params.budget_type : false,
                    case_type: params.case_type ? params.case_type : false,
                    assignee_id: params.assignee ? params.assignee : false
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                params: params,
                employees: this.store.query('employee', {
                    sortField: 'first_name,last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                }),
                budgetTypes: Ember.A([Ember.Object.create({ value: 'Flat Rate', display: 'Flat Rate' }), Ember.Object.create({ value: 'Time & Expense', display: 'Time & Expense' })]),
                caseTypes: this.store.query('matter-type', {
                    active: 1,
                    sortField: 'prefix'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('totalPages', resolved.tableData.total_pages);

            if (resolved.params.owner) {
                var selectedEmployee = resolved.employees.findBy('id', resolved.params.owner);
                controller.set('selectedEmployee', selectedEmployee);
            }
            if (resolved.params.budget_type) {
                var selectedType = resolved.budgetTypes.findBy('value', resolved.params.budget_type);
                controller.set('selectedType', selectedType);
            }
            if (resolved.params.assignee) {
                var selectedAssignee = resolved.employees.findBy('id', resolved.params.assignee);
                controller.set('selectedAssignee', selectedAssignee);
            }
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});