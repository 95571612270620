define('tm3/pods/admin/config/budget-estimated-hours-avg-rate/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Full Access'],

        model: function model() {
            return Ember.RSVP.hash({
                currentRate: this.store.queryRecord('appl-config', {
                    name: 'budgets_estimated_hours_avg_rate.current'
                }),
                oldRates: this.store.query('appl-config', {
                    name: '*budgets_estimated_hours_avg_rate.*',
                    sortField: 'id'
                })
            });
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});