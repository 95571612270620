define('tm3/pods/matter/admin/budgets/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        model: function model(params) {
            var matterId = this.get('currentMatter.id');

            return Ember.RSVP.hash({
                budgets: this.store.query('budget', {
                    matter_id: matterId,
                    with: 'billables'
                }),
                matter: this.store.findRecord('matter', matterId),
                budgetEstimatedHoursAvgRate: this.store.queryRecord('appl-config', {
                    name: 'budgets_estimated_hours_avg_rate.current'
                })
            }).then(function (h) {
                var budgets = h.budgets;

                var promises = budgets.map(function (b) {
                    return b.getProgress();
                });

                return Ember.RSVP.all(promises).then(function (results) {
                    results.forEach(function (res) {
                        var b = budgets.findBy('id', res.budget_progress.id);

                        res.budget_progress.total = parseFloat(res.budget_progress.total);
                        b.set('budgetProgress', res.budget_progress);
                    });

                    return h;
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('globalAverageRate', parseFloat(model.budgetEstimatedHoursAvgRate.get('value')));
        },


        actions: {
            save: function save(budgetChangeset) {
                var _this = this;

                budgetChangeset.validate().then(function () {
                    if (!budgetChangeset.get('isValid')) {
                        _this.get('notify').error('Please review the validation errors');

                        return;
                    }
                    var matter = _this.controller.get('model.matter');

                    budgetChangeset.set('matter', matter);
                    budgetChangeset.set('prevAmount', 0);
                    budgetChangeset.save().then(function () {
                        _this.refresh();
                        _this.controller.send('hideForm');
                    }).catch(function () {
                        _this.validationReport(budgetChangeset);
                    });
                });
            },
            delete: function _delete(budget) {
                var _this2 = this;

                budget.destroyRecord().then(function () {
                    _this2.get('notify').success('Successfully deleted!');
                }).catch(function () {
                    _this2.get('notify').error('Could not delete record!');
                });
            },
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});