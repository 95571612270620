define('tm3/mixins/document-search-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),
        docTypeList: 'jury_doc_type_tm',

        queryParams: {
            t: {
                refreshModel: true
            },
            dt: {
                refreshModel: true
            },
            s: {
                refreshModel: true
            },
            d: {
                refreshModel: true
            },
            m: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var self = this;
            var cm = params.m ? Ember.Object.create({ id: params.m }) : this.get('currentMatter');
            var hasParams = false;

            hasParams = Ember.isPresent(params.t) || Ember.isPresent(params.dt) || Ember.isPresent(params.s) || Ember.isPresent(params.d);

            return Ember.RSVP.hash({
                documentTypes: this.get('lists').getListForGroup(this.get('docTypeList')),
                subjects: cm.get('id') ? this.store.query('subject', {
                    matter_id: cm.get('id'),
                    sort: 'last_name,first_name'
                }) : null,
                documents: hasParams && cm.get('id') ? new Ember.RSVP.Promise(function (resolve, reject) {
                    self.store.createRecord('document').searchDocuments({
                        matter_id: cm.get('id'),
                        contents: params.t,
                        doc_type: params.dt.split(','),
                        subject_id: params.s,
                        doc_name: params.d
                    }).then(function (response) {
                        Ember.run(function () {
                            self.store.unloadAll('document');
                            self.store.pushPayload('document', response);
                            resolve({
                                documents: self.store.peekAll('document'),
                                meta: response.meta
                            });
                        });
                    }).catch(function (error) {
                        self.get('notify').error(error.errors[0].additional_info.title);
                    });
                }) : [],
                params: params,
                matter: cm.get('id') ? this.store.findRecord('matter', cm.get('id')) : null
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            if (Ember.isPresent(model.documents.meta)) {
                controller.set('query', model.documents.meta.sphinx_query);
                controller.set('documents', model.documents.documents);
            } else {
                controller.set('query', '');
                controller.set('documents', model.documents);
            }
            controller.set('terms', model.params.t);
            controller.set('docName', model.params.d);
            controller.set('selectedSubject', model.subjects ? model.subjects.findBy('id', model.params.s) : null);
            controller.set('selectedMatter', model.matter ? model.matter : null);
            if (Ember.isPresent(model.params.dt)) {
                model.documentTypes.forEach(function (dt) {
                    if (model.params.dt.indexOf(dt.get('value')) >= 0) {
                        dt.set('selected', true);
                    }
                });
            }
        },


        actions: {
            search: function search(terms, docTypes, subject, docName) {
                var controller = this.controller;

                controller.set('t', terms);
                controller.set('dt', docTypes.map(function (d) {
                    return d.get('value');
                }));
                if (Ember.isPresent(subject)) {
                    controller.set('s', subject.get('id'));
                } else {
                    controller.set('s', null);
                }
                controller.set('d', docName);
            },


            /** power-select for matters */
            searchMatters: function searchMatters(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchMatters, term, resolve, reject, 600);
                });
            },
            searchSubjects: function searchSubjects(term) {
                var _this2 = this;

                if (!Ember.isEmpty(this.get('controller.selectedMatter'))) {
                    return new Ember.RSVP.Promise(function (resolve, reject) {
                        Ember.run.debounce(_this2, _this2._performSearchSubjects, term, resolve, reject, 600);
                    });
                } else {
                    this.get('notify').error('You must select a case from the case filter first');
                }
            }
        },

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            return this.get('store').query('matter', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        },
        _performSearchSubjects: function _performSearchSubjects(term, resolve, reject) {
            return this.get('store').query('subject', {
                'first_name||last_name': '*' + term + '*',
                matter_id: this.get('controller.selectedMatter.id'),
                limit: 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});