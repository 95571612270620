define('tm3/pods/workflows-browser/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        queryParams: {
            excludeNotAssigned: {
                refreshModel: true
            },
            progressStatus: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                subject_workflows: this.store.query('subject-workflow-v2', {
                    global_assignments: true,
                    exclude_not_assigned: params.excludeNotAssigned,
                    progress_status: params.progressStatus
                }),
                case_workflows: this.store.query('case_workflow', {
                    global_assignments: true,
                    progress_status: params.progressStatus
                }),
                employees: this.get('store').query('employee', {
                    sortField: 'first_name,last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                }),
                owners: this.store.query('employee', {
                    sort: 'first_name,last_name',
                    role: ['Executive', 'Case Manager'],
                    status: 'Active'
                }),
                matterTypes: this.get('store').query('matter-type', {
                    active: '1'
                }),
                collections: this.get('store').query('collection', {}),
                teams: this.get('store').query('team', {
                    mode: 'Employee',
                    with: 'employees'
                }),
                params: params
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.get('statusFilterOptions').pushObjects(model.workflowStatuses.map(function (x) {
                return Ember.Object.create({
                    value: x.get('value'),
                    display: 'Only ' + x.get('display')
                });
            }));
            controller.set('selectedStatusFilter', controller.get('statusFilterOptions').findBy('value', controller.get('filterStatus')));
            controller.set('selectedProgressStatusFilter', controller.get('progressStatusFilterOptions').filter(function (op) {
                if (model.params.progressStatus) {
                    return model.params.progressStatus.includes(op.get('value'));
                } else {
                    return ['Not Started', 'In Progress'].includes(op.get('value'));
                }
            }));
            controller.set('selectedWorkflow', controller.get('workflows').filter(function (op) {
                return controller.get('filterWorkflow').contains(op.get('id'));
            }));
            controller.set('selectedOperator', controller.get('wfOperators').find(function (op) {
                return controller.get('operatorWorkflow') === op.get('value');
            }));
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});