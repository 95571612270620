define('tm3/pods/crm/contacts/info/activity/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        queryParams: {
            refresh: {
                refreshModel: true
            }
        },

        model: function model() {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');
            var clientId = client.get('id');

            return Ember.RSVP.hash({
                employees: this.store.query('employee', { status: 'Active', sort: 'first_name,last_name' }),
                model: this.store.query('crm-note-has-client', {
                    client_id: clientId,
                    with: 'crm_notes'
                }),
                category_totals: this.get('ajax').request('crm_note_has_clients/category_totals?client_id=' + clientId).then(function (response) {
                    return response.category_totals;
                }),
                noteCategories: this.store.findAll('crm-note-category')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model.toArray());
            var clientsInfoModel = this.modelFor('crm.contacts.info').model;
            controller.set('client', clientsInfoModel);
            controller.set('employees', resolved.employees);
            controller.set('category_totals', resolved.category_totals);

            var categories = [];
            var categoryNames = ['Email', 'Conversation', 'Voicemail', 'Social Media'];
            resolved.noteCategories.forEach(function (category) {
                if (categoryNames.indexOf(category.get('name')) >= 0) {
                    categories.pushObject(category);
                }
            });
            controller.set('noteCategories', categories);
        }
    });
});