define('tm3/pods/calendar/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appLists = ['calendar_type'];

    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        queryParams: {
            matter_type_ids: {
                refreshModel: true
            },
            event_types: {
                refreshModel: true
            },
            case_owner_ids: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                matter_type_ids: params.matter_type_ids,
                event_types: params.event_types,
                case_owner_ids: params.case_owner_ids,
                lists: this.get('lists').fetchMultipleLists(appLists),
                caseTypes: this.store.query('matter-type', {
                    sort: 'name'
                }),
                caseOwners: this.store.query('employee', {
                    sort: 'first_name,last_name',
                    status: 'Active',
                    role: ['Executive', 'Case Manager'],
                    with: 'users'
                })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('selectedCaseTypes', model.caseTypes.filter(function (x) {
                return model.matter_type_ids.includes(x.get('id'));
            }));
            var selectedTypes = controller.get('calendarTypes').filter(function (ct) {
                return Ember.isEmpty(model.event_types) ? true : model.event_types.includes(ct.get('display'));
            });
            controller.set('selectedEventTypes', selectedTypes);
            controller.set('selectedCaseOwners', model.caseOwners.filter(function (x) {
                return model.case_owner_ids.includes(x.get('id'));
            }));
        }
    });
});