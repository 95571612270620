define('tm3/pods/admin/employees/list/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        requiredPermissions: ['Manage Employees'],
        queryParams: {
            q: {
                refreshModel: true
            },
            statusFilter: {
                refreshModel: true
            },
            onSchedulerFilter: {
                refreshModel: true
            }
        },
        model: function model(params) {
            var employeeQuery = {
                sort: 'first_name,last_name'
            };

            if (Ember.isPresent(params.q)) {
                employeeQuery['first_name||last_name'] = '*' + params.q + '*';
            }

            if (params.statusFilter !== 'all') {
                employeeQuery.status = params.statusFilter;
            }

            if (params.onSchedulerFilter) {
                employeeQuery.include_in_scheduling = 1;
            }

            return Ember.RSVP.hash({
                employees: this.store.query('employee', employeeQuery)
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('query', controller.get('q'));
        }
    });
});