define('tm3/pods/matter/admin/reports-by-employee/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        goBack: Ember.inject.service(),
        queryParams: ['invoice_group', 'budget'],
        invoice_group: null,
        budget: null,

        allCollapsed: true,

        totalService: Ember.computed('showAllEmployees', 'model', function () {
            if (this.get('showAllEmployees')) {
                var total = 0;
                var userDict = this.get('model.tableData.table_data');
                for (var userId in userDict) {
                    if (userDict[userId]['is_money'] === '1') {
                        var billableGroupsDict = userDict[userId]['billable_groups'];
                        for (var bgId in billableGroupsDict) {
                            total += parseFloat(billableGroupsDict[bgId]['time']['total']);
                        }
                    }
                }
                return total;
            }
            return null;
        }),
        totalExpense: Ember.computed('showAllEmployees', 'model', function () {
            if (this.get('showAllEmployees')) {
                var total = 0;
                var userDict = this.get('model.tableData.table_data');
                for (var userId in userDict) {
                    if (userDict[userId]['is_money'] === '1') {
                        var billableGroupsDict = userDict[userId]['billable_groups'];
                        for (var bgId in billableGroupsDict) {
                            billableGroupsDict[bgId]['expenses'].forEach(function (exp) {
                                total += parseFloat(exp['total']);
                            });
                        }
                    }
                }
                return total;
            }
            return null;
        }),

        customParams: Ember.computed('model', 'invoice_group', 'budget', function () {
            return {
                matter: this.get('model.matter.id'),
                employee: this.get('model.employee.id'),
                invoice_group: this.get('invoice_group') ? this.get('invoice_group') : null,
                budget: this.get('budget') ? this.get('budget') : null
            };
        }),

        tableDataValues: Ember.computed('model.tableData.table_data', function () {
            return Object.values(this.get('model.tableData.table_data'));
        }),

        /*
        used when a billing time for single employee is displayed
        for expanded/collapsed expenses
        */
        recalculateAllCollapsed: function recalculateAllCollapsed() {
            var billGroups = this.get('billGroups');
            var allCollapsed = true;
            for (var id in billGroups) {
                if (billGroups[id]['expanded']) {
                    allCollapsed = false;
                }
            }
            this.set('allCollapsed', allCollapsed);
        },


        /*
        used when data for all employees is displayed
        for expanded/collapsed billable-groups belonging to each employee
        */
        recalculateAllEmployeeCollapsed: function recalculateAllEmployeeCollapsed() {
            var userDict = this.get('model.tableData.table_data');
            var allCollapsed = true;
            for (var userId in userDict) {
                if (userDict[userId]['expanded']) {
                    allCollapsed = false;
                }
            }
            this.set('allEmployeesCollapsed', allCollapsed);
        },


        actions: {
            goBack: function goBack() {
                if (this.get('goBack').canGoToPreviousOrigin()) {
                    this.get('goBack').toPreviousOrigin();
                } else {
                    this.transitionToRoute('matter.admin.reports', this.get('model.matter.id'));
                }
            },

            // when single employee time is displayed -- used for expenses
            expandAll: function expandAll() {
                var billGroups = this.get('billGroups');
                for (var id in billGroups) {
                    Ember.set(billGroups[id], 'expanded', true);
                }
                this.set('allCollapsed', false);
            },
            collapseAll: function collapseAll() {
                var billGroups = this.get('billGroups');
                for (var id in billGroups) {
                    Ember.set(billGroups[id], 'expanded', false);
                }
                this.set('allCollapsed', true);
            },


            // when all employees are displayed -- used for billable-groups
            expandAllEmployees: function expandAllEmployees() {
                var userDict = this.get('model.tableData.table_data');
                for (var userId in userDict) {
                    Ember.set(userDict[userId], 'expanded', true);
                }
                this.set('allEmployeesCollapsed', false);
            },
            collapseAllEmployees: function collapseAllEmployees() {
                var userDict = this.get('model.tableData.table_data');
                for (var userId in userDict) {
                    Ember.set(userDict[userId], 'expanded', false);
                }
                this.set('allEmployeesCollapsed', true);
            },
            collapsedChanged: function collapsedChanged() {
                this.recalculateAllCollapsed();
            },
            collapsedEmployeeChanged: function collapsedEmployeeChanged() {
                this.recalculateAllEmployeeCollapsed();
            }
        }
    });
});