define('tm3/pods/crm/deals/list/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),
        // indicate that when this query param changes, refresh the route
        queryParams: {
            selectedStages: {
                refreshModel: true
            }
        },

        // ask for additional data to be sideloaded
        // could not side load employee until missing id is resolved
        model: function model(params) {
            var dealParams = {
                with: 'all'
            };

            if (typeof params.selectedStages !== 'undefined') {
                dealParams.stage = params.selectedStages;
            }

            return Ember.RSVP.hash({
                deals: this.store.query('deal', dealParams),
                stages: this.get('lists').getListForGroup('crm_stage'),
                selectedStages: params.selectedStages,
                employees: this.store.query('employee', { status: 'Active', sort: 'first_name,last_name' })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, {});
            controller.set('activeStages', resolved.selectedStages);
            controller.set('deals', resolved.deals);
            controller.set('stages', resolved.stages);
            controller.set('employees', resolved.employees);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            delete: function _delete(deal) {
                var self = this;
                deal.destroyRecord().then(function () {
                    self.get('notify').success('Deal Removed');
                    self.refresh();
                });
            }
        }
    });
});