define('tm3/pods/components/matter-conflict-check/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    // used to infer subject names from a case name
    var subjectSearch = /[\w\-'.]+, [\w\-'.]+/g; // `[last name], [first name]`

    exports.default = Ember.Component.extend({
        'data-test-component': 'matter-conflict-check',

        store: Ember.inject.service(),

        hasConflict: false,
        loadingConflicts: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('searchedCaseName', '');
            this.set('searchedSubjectNames', []);
            this.set('casesResults', []);
            this.set('subjectResults', []);
        },


        // eslint-disable-next-line ember/no-observers
        setConflict: Ember.observer('casesResults', 'subjectResults', function () {
            var hasConflict = this.get('hasConflict');
            var hasResults = this.get('casesResults.length') > 0 || this.get('subjectResults.length') > 0;

            if (!hasConflict && hasResults) {
                this.set('hasConflict', true);
            } else if (hasConflict && !hasResults) {
                this.set('hasConflict', false);
            }
        }),

        // eslint-disable-next-line ember/no-observers
        triggerSearch: Ember.observer('matter.{litigationType,name,plaintiff,defendant}', 'vsText', 'shouldSearch', function () {
            // The visual indicator is a huge sticky warning callout, so we can get away with a long debounce delay here.
            if (this.get('shouldSearch')) {
                this.set('loadingConflicts', true);
                Ember.run.debounce(this, this._triggerSearch, 3000);
            } else {
                this.send('reset');
            }
        }),

        shouldSearch: Ember.computed('matter.{litigationType,name,plaintiff,defendant}', function () {
            switch (this.get('matter.litigationType')) {
                case 'N/A':
                    {
                        var caseName = (this.get('matter.name') || '').trim();

                        // don't search until we have a complete case name
                        return caseName.length >= 5;
                    }

                case 'Individual Case':
                    {
                        var plaintiff = (this.get('matter.plaintiff') || '').trim();
                        var defendant = (this.get('matter.defendant') || '').trim();

                        // don't search until we've filled both fields
                        return plaintiff.length >= 3 && defendant.length >= 3;
                    }
                default:
                    return false;
            }
        }),

        _triggerSearch: function _triggerSearch() {
            if (!this.get('shouldSearch')) {
                this.send('reset');

                return;
            }

            var caseName = '';
            var subjectNames = [];

            switch (this.get('matter.litigationType')) {
                case 'N/A':
                    caseName = (this.get('matter.name') || '').trim().toLowerCase();

                    // Find possible subject names in case name (not perfect but good enough for our purposes).
                    subjectNames.push.apply(subjectNames, _toConsumableArray(caseName.matchAll(subjectSearch).map(function (match) {
                        return match[0];
                    })));

                    break;

                case 'Individual Case':
                    {
                        var plaintiff = (this.get('matter.plaintiff') || '').trim().toLowerCase();
                        var defendant = (this.get('matter.defendant') || '').trim().toLowerCase();
                        var vsText = this.get('vsText');

                        caseName = plaintiff + ' ' + vsText + ' ' + defendant;

                        if (plaintiff !== '') {
                            subjectNames.push(plaintiff);
                        }

                        if (defendant !== '') {
                            subjectNames.push(defendant);
                        }

                        break;
                    }

                default:
                    break;
            }

            this.send('searchCases', caseName);
            this.send('searchSubjects', subjectNames);
        },


        unifiedResults: Ember.computed('casesResults', 'subjectResults', function () {
            var _this = this;

            return [].concat(_toConsumableArray(this.get('casesResults')

            // remove cases that are already shown in subject results
            .filter(function (matter) {
                return !_this.get('subjectResults').find(function (subject) {
                    return matter.get('id') === subject.get('matter.id');
                });
            }).map(function (result) {
                return Ember.Object.create({
                    matter: result
                });
            })), _toConsumableArray(this.get('subjectResults').map(function (result) {
                return Ember.Object.create({
                    subject: result,
                    matter: result.get('matter')
                });
            })));
        }),

        columns: Ember.computed('subjectResults.length', function () {
            var columns = [{
                propertyName: 'matter.tmCode',
                title: '#'
            }, {
                propertyName: 'matter.name',
                title: 'Case Style',
                linkTo: 'matter.dashboard',
                linkParam: ['matter.id'],
                linkTarget: '_blank'
            }, {
                propertyName: 'matter.status',
                title: 'Status'
            }];

            if (this.get('subjectResults.length')) {
                columns.unshift({
                    propertyName: 'subject.fullName',
                    linkTo: 'matter.subjects.info',
                    linkParam: ['matter.id', 'subject.id'],
                    linkTarget: '_blank',
                    linkToDepends: 'subject.hasAccess',
                    title: 'Subject Name'
                });
            }

            return columns;
        }),

        actions: {
            reset: function reset() {
                this.set('casesResults', []);
                this.set('subjectResults', []);
                this.set('hasConflict', false);
                this.set('loadingConflicts', false);
                this.set('searchedCaseName', '');
                this.set('searchedSubjectNames', []);
            },
            searchCases: function searchCases(caseName) {
                var _this2 = this;

                if (this.get('searchedCaseName') === caseName) {
                    this.set('loadingConflicts', false);

                    return;
                }
                this.set('searchedCaseName', caseName);

                if (caseName === '') {
                    this.set('casesResults', []);
                    this.set('loadingConflicts', false);

                    return;
                }

                this.get('store').query('matter', {
                    fuzzy_search: caseName,
                    limit: 5
                }).then(function (result) {
                    return _this2.set('casesResults', result);
                }).finally(function () {
                    return _this2.set('loadingConflicts', false);
                });
            },
            searchSubjects: function searchSubjects(subjectNames) {
                var _this3 = this;

                if (this.get('searchedSubjectNames').join('|') === subjectNames.join('|')) {
                    this.set('loadingConflicts', false);

                    return;
                }
                this.set('searchedSubjectNames', subjectNames);

                if (subjectNames.length === 0) {
                    this.set('subjectResults', []);
                    this.set('loadingConflicts', false);

                    return;
                }

                this.get('store').query('subject', {
                    custom_endpoint: 'subjects/conflict_search',
                    fuzzy_search: subjectNames, // string[]
                    limit: 10,
                    with: 'matters'
                }).then(function (result) {
                    return _this3.set('subjectResults', result);
                }).finally(function () {
                    return _this3.set('loadingConflicts', false);
                });
            }
        }
    });
});