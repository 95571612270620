define('tm3/pods/accounting/billing/manual-expense/add/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        requireAnyPermission: ['Manage Manual Expenses'],

        model: function model(params) {
            return Ember.RSVP.hash({
                billableGroup: Ember.Object.create({
                    start: null
                }),
                employees: this.store.query('employee', {
                    status: 'Active',
                    sort: 'first_name,last_name'
                }),
                invoiceGroups: params.matter ? this.store.query('invoice-group', {
                    matter_id: params.matter
                }) : null,
                budgets: params.matter ? this.store.query('budget', {
                    matter_id: params.matter,
                    enabled: 1
                }) : null,
                billableTypes: this.store.findAll('billable-type'),
                billables: [],
                currentEmployee: this.store.findRecord('employee', this.get('session.data.authenticated.id')),
                billing_phrases: this.store.query('phrase', {
                    with: 'phrase_case_type_group,phrase_case_type_group_matter_type',
                    phrase_type: 'billing',
                    soft_deleted: 0,
                    sort: 'billable_type_id,order,name,content'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.resetMassInputs();
            controller.addExpense();
            controller.set('overallExpenseType', null);
        },


        actions: {
            /**
             * save all the data related to the billable-group
             */
            save: function save() {
                var _this = this;

                var billableGroupPojo = this.controller.get('model.billableGroup');
                var expenses = this.controller.get('model.billables');

                var valid = true;
                expenses.forEach(function (expense) {
                    if (!Ember.isPresent(expense.get('matter'))) {
                        valid = false;
                        _this.get('notify').error('Each expense in the expense list must have a matter selected');
                    }

                    if (!Ember.isPresent(expense.get('invoiceGroup'))) {
                        valid = false;
                        _this.get('notify').error('Each expense in the expense list must have an invoice group');
                    }

                    if (!Ember.isPresent(expense.get('billableType'))) {
                        valid = false;
                        _this.get('notify').error('Each expense in the expense list must have a Billable Type');
                    }
                });

                if (valid) {
                    var billableGroup = this.store.createRecord('billable-group', {
                        createdBy: this.controller.get('model.currentEmployee'),
                        type: 'Manual',
                        start: billableGroupPojo.get('start'),
                        approvalStatus: null
                    });

                    var promises = [];

                    billableGroup.save().then(function (result) {
                        expenses.forEach(function (expense) {
                            var billable = _this.store.createRecord('billable', {
                                cut: expense.get('cut'),
                                qty: expense.get('qty'),
                                rate: expense.get('rate'),
                                billType: 'Manual',
                                billableGroup: result,
                                billableType: expense.get('billableType'),
                                expense: true,
                                description: expense.get('description'),
                                billed: expense.get('billed'),
                                matter: expense.get('matter'),
                                budget: expense.get('budget'),
                                invoiceGroup: expense.get('invoiceGroup')
                            });

                            promises.pushObject(billable.save().catch(function (error) {
                                _this.validationReport(billable);
                                throw error;
                            }));
                        });

                        Ember.RSVP.all(promises).then(function () {
                            _this.transitionTo('accounting.billing.manual-expense.list', {
                                queryParams: { selected: billableGroup.get('id') }
                            });
                            _this.get('notify').success('Successfully saved');
                        }).catch(function () {
                            _this.get('notify').error('Something was wrong while saving the data');
                        });
                    }).catch(function () {
                        _this.validationReport(billableGroup);
                    });
                }
            },


            /**
             * destroy an expense record from the list
             */
            removeExpense: function removeExpense(expense) {
                var expenses = this.get('controller.model.billables');
                expenses.removeObject(expense);
            }
        }
    });
});