define('tm3/pods/crm/contacts/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var id = params.user_id;

            return Ember.RSVP.hash({
                model: this.store.query('client', {
                    id: id,
                    with: 'user_numbers,user_addrs,user_urls,firm_has_clients,client_invoice_history'
                }),
                user: this.store.query('user', { id: id, with: 'event_logs' }).then(function (users) {
                    return users.get('firstObject');
                }),
                clientTags: this.store.query('crm-tag-has-client', {
                    client_id: id,
                    with: 'crm_tags'
                }),
                tags: this.store.query('crm-tag', {}),
                todos: this.store.query('todo', {
                    client_id: id,
                    with: 'all',
                    archived: 0
                }),
                employees: this.store.query('employee', { status: 'Active', sort: 'first_name,last_name' }),
                todoCategories: this.store.findAll('todo-category'),
                newTodo: this.store.createRecord('todo', { priority: 'Normal' })
            });
        },
        setupController: function setupController(controller, resolved) {
            var model = resolved.model.get('firstObject');
            this._super(controller, model);

            // load data for tagging system
            var tagsArray = [];
            tagsArray.addObjects(resolved.clientTags);

            controller.set('clientTags', tagsArray);
            controller.set('tags', resolved.tags);
            controller.set('todos', resolved.todos);
            controller.set('employees', resolved.employees);
            controller.set('todoCategories', resolved.todoCategories);
            controller.set('user', resolved.user);
            controller.set('newTodo', resolved.newTodo);
        },


        // redirect when going to the regular info route
        afterModel: function afterModel(resolvedModel, transition) {
            var self = this;
            transition.then(function () {
                // Ember.Logger.debug(self.get('router.url'));
                var pieces = self.get('router.url').split('/').length;
                if (pieces === 5) {
                    self.transitionTo('crm.contacts.info.activity');
                }
            });
        },


        actions: {
            // duplicate so this action can be reached by nested routes
            refreshInfoRoute: function refreshInfoRoute() {
                this.refresh();
            },
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});