define('tm3/pods/components/simple-table/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['table', 'table-striped', 'table-bordered'],
        tagName: 'table',
        columns: [],
        data: [],

        onInitComponent: Ember.on('init', function () {
            if (this.get('noBorders')) {
                this.set('classNames', ['table']);
            }
        }),

        // Convert some string to the human readable one
        _propertyNameToTitle: function _propertyNameToTitle(name) {
            return Ember.String.capitalize(Ember.String.dasherize(name).replace(/-/g, ' '));
        },


        // add human readable propertyName if missing
        columnsProcessor: Ember.on('init', Ember.observer('columns.@each', function () {
            var _this = this;

            this.get('columns').forEach(function (column) {
                if (Ember.isNone(Ember.get(column, 'propertyName'))) {
                    return;
                }
                var propertyName = Ember.get(column, 'propertyName');
                if (Ember.isNone(Ember.get(column, 'title'))) {
                    Ember.set(column, 'title', _this._propertyNameToTitle(propertyName));
                }
            });
        })),
        actions: {
            getValue: function getValue(row, property) {
                return row.get(property);
            },
            sendAction: function sendAction(action, params) {
                this.sendAction(action, params);
            }
        }
    });
});