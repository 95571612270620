define('tm3/pods/matter-list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentRoute: 'matter-list',

        queryParams: {
            collection_id: {
                refreshModel: true
            }
        },

        modelName: 'matter',
        session: Ember.inject.service(),
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),

        previousCollection: false,

        model: function model(params) {
            return Ember.RSVP.hash({
                workflows: this.store.query('workflow', { workflow_type: 'matter' }),
                employees: this.get('store').query('employee', {
                    sortField: 'first_name,last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                }),
                collections: this.store.findAll('collection', { backgroundReload: false }),
                customColumns: this.store.query('collection-column', {
                    collection_id: Ember.isPresent(params.collection_id) ? params.collection_id : 61
                    // Use Global View manage the global view
                }),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                tags: this.store.query('matter-tag', {
                    sortField: 'name',
                    limit: 999999
                }),
                privateTags: this.store.query('matter-private-tag', {
                    sortField: 'name',
                    limit: 999999
                }),
                billableTypes: this.store.query('billable-type', {
                    time: 1
                }),
                collection_id: params.collection_id,
                currentEmployee: this.store.findRecord('employee', this.get('session.data.authenticated.id'))
            });
        },
        setupController: function setupController(controller, resolved) {
            var params = {
                include_jury_calculations: true,
                include_days_since: !!resolved.customColumns.findBy('apiName', 'calc_days_since_creation') || !!resolved.customColumns.findBy('apiName', 'calc_days_since_last_report') || !!resolved.customColumns.findBy('apiName', 'calc_days_since_last_invoice')
            };
            if (resolved.collection_id) {
                controller.set('overrideTableSettings', {
                    collection_id: resolved.collection_id
                });
                controller.set('collection', resolved.collections.findBy('id', resolved.collection_id));
            }

            controller.set('loadDataParams', params);
            controller.set('currentEmployee', this.store.findRecord('employee', this.get('session.data.authenticated.id')));

            controller.set('workflows', resolved.workflows);
            controller.set('workflowStatuses', resolved.workflowStatuses);
            controller.set('employees', resolved.employees);
            controller.set('collections', resolved.collections);
            controller.set('billableTypes', resolved.billableTypes);
            if (!this.get('session.data.authenticated.groups').findBy('name', 'ROOT')) {
                controller.set('collections', resolved.collections.rejectBy('name', 'Global View'));
            }

            controller.set('customColumns', resolved.customColumns);

            if (Ember.isEmpty(controller.get('additionalColumnsForFilter'))) {
                controller.set('additionalColumnsForFilter', controller.generateAdditionalColumns());
            }

            if (this.get('previousCollection') !== resolved.collection_id) {
                controller.set('columns', controller.generateColumns());
            }
            this.set('previousCollection', resolved.collection_id);
            if (resolved.collection_id) {
                controller.setCollectionId(resolved.collection_id);
            }

            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            },
            afterSaveCaseGroup: function afterSaveCaseGroup() {
                this.refresh();
                this.get('notify').success('The new group has been saved');
                this.controller.set('isAddingCaseGroup', false);
            },
            afterCancelCaseGroup: function afterCancelCaseGroup() {
                this.controller.set('isAddingCaseGroup', false);
            },
            afterSaveCase: function afterSaveCase() {
                this.refresh();
                this.send('selectAll', false);
                this.get('notify').success('Case(s) added to selected group');
                this.controller.set('isAddingCase', false);
            },
            afterCancelCase: function afterCancelCase() {
                this.controller.set('isAddingCase', false);
            },
            afterSaveClientCollection: function afterSaveClientCollection() {
                this.refresh();
                this.send('selectAll', false);
                this.get('notify').success('Case(s) added to selected client collection');
                this.controller.set('isAddingClientCollection', false);
            },
            afterCancelClientCollection: function afterCancelClientCollection() {
                this.controller.set('isAddingClientCollection', false);
            },
            didTransition: function didTransition() {
                var self = this;
                Ember.run.schedule('afterRender', self, function () {
                    Ember.$('.quickSearchInput').focus();
                });
            },
            willTransition: function willTransition(transition) {
                this.controller.set('addTimeToMatter', null);
            }
        }
    });
});