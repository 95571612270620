define('tm3/pods/me/scheduling/route', ['exports', 'moment', 'tm3/mixins/scheduling-route'], function (exports, _moment, _schedulingRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_schedulingRoute.default, {
        ajax: Ember.inject.service(),
        lists: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        requiredPermissions: ['Manage My Schedule'],

        queryParams: {
            add: {
                refreshModel: true
            },
            matter_id: {
                refreshModel: true
            },
            workflow: {
                refreshModel: true
            },
            workflow_id: {
                refreshModel: true
            },
            subject_workflow_id: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                current_user: this.store.findRecord('user', this.get('session.data.authenticated.id')),
                current_employee: this.store.query('employee', {
                    id: this.get('session.data.authenticated.id'),
                    status: 'Active',
                    include_in_scheduling: '1',
                    sort: 'first_name,last_name'
                }),
                start_date: (0, _moment.default)(new Date()).format('YYYY-MM-DD'),
                all_allocation_types: this.store.findAll('time_allocation_type', { backgroundReload: false }),
                cases_selected: this.get('casesSelected'),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                case_workflows: this.store.query('case_workflow', {
                    assignments: true
                }),
                subject_workflows: this.store.query('subject-workflow-v2', {
                    assignments: true
                }),
                selectedCase: Ember.isPresent(params.matter_id) ? this.store.findRecord('matter', params.matter_id) : null,
                params: params
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.get('statusFilterOptions').pushObjects(model.workflowStatuses.map(function (x) {
                return Ember.Object.create({
                    value: x.get('value'),
                    display: 'Only ' + x.get('display')
                });
            }));
            controller.set('selectedStatusFilter', controller.get('statusFilterOptions').findBy('value', controller.get('filterStatus')));

            if (Ember.isPresent(model.params.workflow) || Ember.isPresent(model.params.workflow_id) || Ember.isPresent(model.params.subject_workflow_id)) {
                var comment = void 0;var deadline = void 0;var hours = void 0;

                var assignedTo = void 0;

                if (Ember.isPresent(model.params.subject_workflow_id)) {
                    var sw = model.subject_workflows.findBy('id', model.params.subject_workflow_id);

                    if (sw) {
                        comment = sw.get('comment');
                        deadline = sw.get('deadline');
                        hours = sw.get('hours');
                        assignedTo = model.current_user.get('id');
                        controller.set('subjectWorkflowId', model.params.subject_workflow_id);
                    }
                } else if (Ember.isPresent(model.params.workflow_id)) {
                    var cw = model.case_workflows.findBy('id', model.params.workflow_id);

                    if (cw) {
                        comment = cw.get('comment');
                        deadline = cw.get('deadline');
                        hours = cw.get('hours');
                        assignedTo = cw.get('assignedToId');
                    }
                } else {
                    var wFieldName = Ember.String.camelize(model.params.workflow);

                    comment = model.selectedCase.get(wFieldName + 'C');
                    deadline = model.selectedCase.get(wFieldName + 'Deadline');
                    hours = model.selectedCase.get(wFieldName + 'Hours');
                    assignedTo = model.selectedCase.get(wFieldName + 'A.id');
                }

                if (model.params.add && model.selectedCase && model.current_employee.get('firstObject') && model.current_user.get('id') === assignedTo) {
                    controller.set('allocationModalCases', [model.selectedCase]);
                    controller.set('allocationModalEmployee', model.current_employee.get('firstObject'));
                    controller.set('allocationModalEmployeeAsUser', model.current_user);
                    controller.set('allocationModalDescription', comment);
                    controller.set('allocationModalDeadline', deadline);
                    controller.set('allocationModalDuration', hours);
                    controller.set('allocationModalType', model.all_allocation_types.findBy('name', 'Working'));
                    controller.set('allocationModalView', 'create');
                    controller.set('currentTab', 'details');
                    controller.set('showAllocationModal', true);
                    controller.set('allocationModalDaysToCopy', controller._buildDaysToCopy(controller.get('start_date')));
                }
            }
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});